const jsonData = [
    {
        id:1,
        by: "Thomas Adams",
        content:"Diwise UK's web design team's professionalism and expert guidance ensured a seamless and  productive collaboration. If you seek a web design partner committed to excellence, I wholeheartedly recommend Diwise UK!"
    },
    {
        id:2,
        by: "Joseph Anderson",
        content:"Diwise ,Digital Media Health Services have been a boon for me. Their innovative approach to         mental wellness and self-care through Digital Media is truly commendable. What sets Diwise         apart is their personalization. I'm grateful for their approach to digital mental health, and I         highly recommend Diwise to anyone seeking holistic self-improvement."
    },
    {
        id:3,
        by: "David Barnes",
        content:"PR branding services have been nothing short of outstanding at Diwise. They have built our         reputation. Their professionalism, creativity, and commitment to our success are         commendable. I highly recommend Diwise UK to any business looking to elevate its brand         presence and reputation through top-notch PR branding services. They are a game-changer! "
    },
    {
        id:4,
        by: "William Bradley",
        content:"Diwise UK's social media management services team is exceptionally skilled at creating          engaging content, optimizing ad campaigns, and growing our online presence. Diwise is the         best option for anyone looking to elevate their social media marketing efforts."
    }
]
export default jsonData