import React, { useEffect, useRef, useState } from "react";
import "./contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { GrInstagram } from 'react-icons/gr'
import { FaFacebookF } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaPhoneAlt } from 'react-icons/fa'
import { ImLocation } from 'react-icons/im'
import { HiMail } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Contact() {
  const [fname, setfname] = useState('')
  const [lname, setlname] = useState('')
  const [mail, setmail] = useState('')
  const [phone, setphone] = useState('')
  const [message, setmessage] = useState('')
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  useEffect(() => {
    AOS.init();
    setDate(new Date().toLocaleDateString());
    setTime(new Date().toLocaleTimeString());
  }, []);
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const submitHandler = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('FirstName', fname);
    formData.append('LastName', lname);
    formData.append('Phone', phone);
    formData.append('Email', mail);
    formData.append('Message', message);
    formData.append('Date', date);
    formData.append('Time', time);

    fetch(
      'https://script.google.com/macros/s/AKfycbygjH8gzSlDED3Q-EWE1jn-0wQiFmbI60PR1Ar55XKYBmYd3zm_nmvt6lPEeVhzlbBe/exec',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((res) =>{
        toast.success('Message sent successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
          theme: "light",
          className: 'custom-toast',
        });
        inputRef1.current.value = '';
        inputRef2.current.value = '';
        inputRef3.current.value = '';
        inputRef4.current.value = '';
        inputRef5.current.value = '';
      })
      .catch((error) => {
        toast.error('Something went wrong!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: 'custom-toast',
          });
      });

  }
  return (
    <>
      <Helmet>
        <title>Contact DIwise - Brand Building Agency in UK</title>
        <meta name="description" content="Contact us for queries related to PR and brand building, market research, social media marketing, web development, search engine optimization, and advertising." />
        <meta name="keyword" content="contact diwise, query for diwise, branding related query" />
        <link rel="canonical" href="https://diwise.uk/contact/" />
      </Helmet>
      <div className="contact">
        <div className="contact-container">
          <div className="contact-box">
            <div className="box1 box" data-aos="fade-left" data-aos-delay="200" data-aos-duration="500" >
              <form  onSubmit={submitHandler}>

                <input type="text" name="fname" placeholder="First Name" id="fname" onChange={(e) => setfname(e.target.value)} required ref={inputRef1} />
                <input type="text" name="lname" placeholder="Last Name" id="lname" onChange={(e) => setlname(e.target.value)} required ref={inputRef2}/>
                <input type="mail" name="mail" placeholder="Email" id="mail" onChange={(e) => setmail(e.target.value)} required ref={inputRef3}/>
                <input type="number"  name="phone" placeholder="Phone" id="phone" onChange={(e) => setphone(e.target.value)} required ref={inputRef4}/>
                <textarea name="message" id="mssg" cols="30" placeholder="Type Your Message here...." rows="10" onChange={(e) => setmessage(e.target.value)} ref={inputRef5}></textarea>
                <input type="submit" id="subbtn" value="Send" className="btn" />
              </form>

            </div>
            <div className="box2 box " data-aos="fade-down" data-aos-delay="200" data-aos-duration="500">
              <h2> <span>Contact</span> us</h2>
            </div>
            <div className="box3 box" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">


              <a href="tel:+447398814161" className="contact-details">
                <div className="contact-icons">
                  <FaPhoneAlt />
                </div>
                <p>+44 7398 814161</p>
              </a>
              <a href="mailto:contact@diwise.uk" className="contact-details">
                <div className="contact-icons">
                  <HiMail />
                </div>
                <p>contact@diwise.uk</p>
              </a>
              <a href="https://www.google.com/maps/place/20+Tiller+Rd,+London+E14+8NN,+UK/@51.496442,-0.023376,325m/data=!3m1!1e3!4m6!3m5!1s0x487602c07cb69a7d:0x5c3397ee8d9f133f!8m2!3d51.496442!4d-0.023376!16s%2Fg%2F11cscvky_g?hl=en&entry=ttu"
                target="_blank" className="contact-details">
                <div className="contact-icons">
                  <ImLocation />
                </div>
                <p>Docklands Business Centre, Suite 12/3D 10-16 Tiller Road, London, England E14 8PX</p>
              </a>

            </div>
            <a className="box4 box" data-aos="fade-up" data-aos-delay="1000" href='https://www.facebook.com/DIwiseLtd' target="_blank"><FaFacebookF /></a>
            <a className="box5 box" data-aos="fade-up" data-aos-delay="1000" href="https://www.instagram.com/diwiseltd/" target="_blank" ><GrInstagram /></a>
            <a className="box6 box" data-aos="fade-up" data-aos-delay="1000" href='https://twitter.com/i/flow/login?redirect_after_login=%2FDIwiseLtd' target="_blank"><FaXTwitter /></a>
            <a className="box7 box" data-aos="fade-up" data-aos-delay="1000" href='https://www.linkedin.com/company/diwiseltd' target="_blank"><FaLinkedinIn /></a>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
        
      />
    </>
  );
}

export default Contact;
