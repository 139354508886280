import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./BlogLayout.css";
const BlogLayout = () => {
  const [blog, setblog] = useState([]);
  const { blog_id } = useParams();
  const fetchData = async (baseUrl) => {
    try {
      const response = await fetch(baseUrl);
      const json = await response.json();
      setblog(json);
      // console.log(json)
    } catch (error) {
      // console.log("error", error);
    }
  };
  const baseUrl = "https://backend.diwise.uk/api";
  useEffect(() => {
    fetchData(baseUrl + "/blogs/" + blog_id + "/");
  }, []);
  function formatDate(date) {
    const options = {day:"numeric", month: "numeric",year: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  }
  return (
    <div className="layout">
      <div className="layout-container">
        <div className="top">
          <div
            className="imgbox"
            style={{ backgroundImage: `url(${blog.image})` }}
          ></div>
          <div className="overlay">
            <h1>{blog.title}</h1>
          </div>
        </div>
        <div className="mid">
          <div className="date"> {blog.date ? formatDate(blog.date) : ""}</div>
          <div className="category">
            {blog.category ? blog.category.name : "No category"}
          </div>
        </div>
        <div className="bottom">
          <div
            className="data"
            dangerouslySetInnerHTML={{ __html: blog.blog_content }}
          />
          <Link to="/blogs" className="btn">
            All Blogs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogLayout;
